import React          from 'react'
import PropTypes      from 'prop-types'
import { navigate }   from 'gatsby'
import { isLoggedIn } from '../utils/auth'


class PrivateRoute extends React.Component {

  componentDidMount() {
    const { path } = this.props
    if (!isLoggedIn() && path !== '/login') {
      // If we’re not logged in, redirect to the landing page.
      navigate('/login')
    }
  }

  render() {
    const { component: Component, ...otherProps } = this.props
    if (isLoggedIn) {
      return <Component {...otherProps} />
    }
    return null
  }

}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute
