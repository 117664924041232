import React                      from 'react'
import Typography                 from '@material-ui/core/Typography'
import { makeStyles }             from '@material-ui/core/styles'

import logo                       from '../images/logo.png'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 40,
    margin: theme.spacing(1),
  },
}))

export default function Copyright() {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <img className={classes.logo} src={logo} alt="" />
      <Typography variant="body2" color="textSecondary" align="center">
        Copyright © BMW Financial&nbsp;
        {new Date().getFullYear()}
      </Typography>
    </div>
  )
}
